
    import {defineComponent, ref, inject} from "vue";
    import {useRoute} from "vue-router";
    import axios from "@/api/axios";
    import Toast from "@/components/UI/Toast";
    import dateFormat from "@/utils/dateFormat";

    export default defineComponent({
        name: "OrderDetail",
        setup() {
            //   /M/User/OrderDetail/3153
            const userInfo = inject("userInfo") as any;
            const biaowuPriceOpen = ref();
            const productDefaultPic = ref();
            const postageFee = ref();
            const entity = ref(null);
            const list = ref([]);
            const route = useRoute();
            const id = route.query.id;
            const expendSwitch = ref([true, true, false]);
            axios
                .get("/M/User/OrderDetail/" + id, {params: {validate: true}})
                .then((res) => {
                    if (res.data.success) {
                        entity.value = res.data.obj.entity;
                        list.value = res.data.obj.entity.orderDetail;
                        biaowuPriceOpen.value = res.data.obj.biaowuPriceOpen
                        productDefaultPic.value = res.data.obj.productDefaultPic
                        postageFee.value = res.data.obj.postageFee
                    } else {
                        Toast({
                            type: "error",
                            title: res.data.msg,
                        });
                    }
                })
                .catch((e) => {
                    Toast({
                        type: "error",
                        title: e.message || e,
                    });
                });

            function switchExpend(index: number, value: boolean) {
                expendSwitch.value[index] = !value;
            }

            function PayNow(orderNum: any) {
                window.location.href = "/Checkout?orderNum=" + orderNum;
            }

            return {
                entity,
                dateFormat,
                list,
                expendSwitch,
                switchExpend,
                biaowuPriceOpen,
                productDefaultPic,
                postageFee,
                userInfo,
                PayNow
            };
        },
    });
